<template>
  <div>
    <gl-title title="订单查询"></gl-title>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="nickname" label="用户昵称">
          <gl-list-search v-model="mFormData.nickname" placeholder="用户昵称"></gl-list-search>
        </el-form-item>
        <el-form-item prop="ifClose" label="是否结算">
          <gl-option v-model="mFormData.ifClose" :list="enumList"></gl-option>
        </el-form-item>
        <el-form-item prop="startTime" label="订单时间">
          <gl-date-picker 
            v-model="mFormData.dateTime" 
            :startTime.sync="mFormData.startTime" 
            :endTime.sync="mFormData.endTime">
          </gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="orderId" label="订单ID"></el-table-column>
        <el-table-column prop="title" label="商品标题"></el-table-column>
        <el-table-column prop="value" label="商品规格"></el-table-column>
        <el-table-column prop="value" label="下单人昵称"></el-table-column>
        <el-table-column prop="value" label="收货人名称"></el-table-column>
        <el-table-column prop="value" label="收货人电话"></el-table-column>
        <el-table-column prop="value" label="收货人地址"></el-table-column>
        <el-table-column prop="value" label="快递单号"></el-table-column>
        <el-table-column prop="value" label="订单状态"></el-table-column>
        <el-table-column prop="value" label="订单时间"></el-table-column>
        <el-table-column prop="value" label="付款时间"></el-table-column>
        <el-table-column prop="value" label="订单金额"></el-table-column>
        <el-table-column prop="closeAndAccount" label="结算状态">
          <template slot-scope="{row}">
            <span v-if="row.closeAndAccount"> 已结算 </span>
            <span> 未结算 </span>
          </template>
        </el-table-column>
        <el-table-column prop="drawingsMode" label="付款方式">
          <template slot-scope="{row}">
            <span v-if="row.drawingsMode"> 支付宝 </span>
            <span v-if="row.drawingsMode"> 银行卡 </span>
            <span> 余额 </span>
          </template>
        </el-table-column>
        <el-table-column prop="orderStatus" label="订单类型">
          <template slot-scope="{row}">
            <span v-if="row.orderStatus"> 普通订单 </span>
            <span> 预售订单 </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130px" fixed="right">
          <template slot-scope="{row}">
            <el-button type="text" @click="onClickAction('orderStatusVisible', row)"> 订单状态 </el-button>
            <!-- <el-button type="text" @click="onClickAction('previewVisible', row)"> 查看 </el-button> -->
            <el-button type="text" @click="$router.push({path: 'detail', query: {itemId: row.itemId}})"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <status :visible.sync="orderStatusVisible" :itemId="itemId"/>
    <preview :visible.sync="previewVisible" :itemId="itemId"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import status from './status.vue'
import preview from './preview.vue'
export default {
  name: 'orderQueryList',
  components: {status,preview},
  mixins: [ListMixin],
  data(){
    return{
      orderStatusVisible: false,
      previewVisible: false,
      itemId: '',
      enumList: [
        {name: '是', value: '1'},
        {name: '否', value: '2'},
      ],
    }
  },
  mounted(){
    this.mGetListFun 
    this.mGetList()
  },
  methods: {
    onClickAction(name, item){
      this.itemId = item.id || ''
      this[name] = true
    }
  },
}
</script>

<style>

</style>